/**
 * = Icon boxes
 */

.icon-box{
	padding: $icon-box-padding;
	position: relative;
	
	.icon-box-body{
		position: relative;
	}
	
}

.icon {
	text-align: center;
	display: inline-flex;
	@include align-items(center);
	@include justify-content(center);

	span, svg {
		font-size: $icon-size;
	}

	&.icon-small{
		span,svg{
			font-size: 0.675rem;
		}
	}

	&.icon-xs{
		span, svg {
			font-size: $icon-size-xs;
		}
	}

	&.icon-sm{
		span, svg {
			font-size: $icon-size-sm;
		}
	}

	&.icon-lg{
		span, svg {
			font-size: $icon-size-lg;
		}
		svg{
			height: 2rem;
		}
	}

	&.icon-xl{
		span{
			font-size: $icon-size-xl;
		}
		svg{
			height: 3.5rem;
		}
	}

	&.w-20{
		width: 20px;
	}

	&.w-30{
		width: 30px;
	}

}

// Icons included in shapes
.icon-shape {
	width: $icon-shape;
	height: $icon-shape;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	span, svg {
		font-size: $icon-size;
	}

	&.icon-xs {
		width: $icon-shape-xs;
		height: $icon-shape-xs;
		span, svg {
			font-size: $icon-size-xs;
		}
	}

	&.icon-sm {
		width: $icon-shape-sm;
		height: $icon-shape-sm;
		span, svg {
			font-size: $icon-size-sm;
		}
	}
	
	&.icon-lg{
		width: $icon-shape-lg;
		height: $icon-shape-lg;
		span, svg {
			font-size: $icon-size-md;
		}
	}
}


.list-icon{
	width: 45px;
}
 

//Colors for icons
@each $color, $value in $theme-colors {
	.icon-shape-#{$color} {
		@include icon-shape-variant($value);
	}
	.icon-#{$color} {
		span{
			color:$value;
		}
	}

	.fill-#{$color}{
		fill:$value;
	}
}

.shape-xs {
    width: 10px;
    height: 10px;
}

.icon-badge{
	display: inline-block;
    position: absolute;
    top: -11px;
    right: 6px;
    height: 7px;
    width: 7px;
    background-color: $danger;
}

//Index Big Icons
.bootstrap-big-icon {
	position: absolute;
	opacity: .05;
	transform: rotate(17deg);
	@include media-breakpoint-up(lg) {
		right: -20%;
		bottom: 6%;
	}

	@include media-breakpoint-up(xl) {
		right: -10%;
		bottom: 6%;
	}

}

.github-big-icon{
	position: absolute;
    right: 80px;
    top: 165px;
	span{
		font-size: 800px;
    	opacity: .1;
	}
}